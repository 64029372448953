import type { Location } from '@thrivesports/shared';
import React, { useContext } from 'react';

import { UserLocationCtx } from '../context/UserLocationCtx.js';
import { useIsAtUserLocation } from '../hooks/useIsAtUserLocation.js';
import lineDistance from '../utils/geometry/lineDistance.js';

export const DistanceFrom: React.FC<{
  closestLocation: Location;
}> = ({ closestLocation }) => {
  const { latLng: userLatLng } = useContext(UserLocationCtx);
  const isAtUserLocation = useIsAtUserLocation();

  // only show if we are using the user's current location
  if (!isAtUserLocation || closestLocation?.point == null) {
    return null;
  }

  const delta = lineDistance(
    closestLocation.point.lat,
    closestLocation.point.lon,
    userLatLng.lat,
    userLatLng.lng,
  );
  return (
    <p className="tssm-coach-card-loc-dist">
      {delta > 1 ? `${delta.toFixed(0)} miles` : 'less than a mile'}
    </p>
  );
};

import './starRating.scss';

import React, { useMemo } from 'react';

export type StarRatingProps = {
  stars: number;
  className?: string;
};

export const StarRating: React.FC<StarRatingProps> = ({ stars, className }) => {
  const starArray = useMemo(() => new Array(5).fill(null), []);

  return (
    <div className={`${className || ''} ts-star-rating`}>
      <div className="ts-star-rating-stars-bg">
        {starArray.map((_, starIndex) => (
          <span key={starIndex} className="tsi-star ts-star-rating-star"></span>
        ))}
      </div>
      <div className="ts-star-rating-stars-fg">
        <div
          style={{
            width: `${(Number(stars) / 5) * 100}%`,
          }}
          className="ts-star-rating-stars"
        >
          {new Array(5).fill(null).map((star, starIndex) => (
            <span
              key={starIndex}
              className="tsi-star ts-star-rating-sel-star"
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

import { backgroundPlaceholder } from '@thrivesports/shared';
import React, { useContext } from 'react';

import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { getMinimumEventPrice } from '../utils/events.js';
import type { SearchResultsEventHitInterface } from '../utils/types.js';

export interface EventTabPropsInterface {
  event: SearchResultsEventHitInterface;
  className?: string;
}

const EventTab = (props: EventTabPropsInterface) => {
  const AppAttributes = useContext(AppAttrsCtx);

  // const [nextBookDate, setNextBookDate] = useState<DateTime | null>(null);
  // const [bookedCount, setBookedCount] = useState<number>(0);
  // const [cost, setCost] = useState<number | null>(null);
  // const [isCostForAdults, setIsCostForAdults] = useState<boolean>(false);
  // const [coaches, setCoaches] = useState<Array<string>>([]);
  // const [bookingAvail, setBookingAvail] = useState<boolean>(false);

  // eslint-disable-next-line no-underscore-dangle
  const eventSource = props.event._source;

  // useEffect(() => {
  //   let nextBookDateRaw: DateTime | null = null;
  //   let bookedCountRaw = 0;
  //   let costRaw: number | null = null;
  //   let isCostForAdultsRaw = false;
  //   let coachesRaw: Array<string> = [];
  //   // Go through schedule to compare bookings and get the most recent and accurate booking and pricing
  //   if (eventSource.schedule) {
  //     eventSource.schedule.forEach((schedule) => {
  //       if (schedule.booking) {
  //         schedule.booking.forEach((booking) => {
  //           const newBookDate = DateTime.fromSQL(booking.booked_date);
  //           if (nextBookDateRaw == null) {
  //             nextBookDateRaw = DateTime.fromSQL(booking.booked_date);
  //             bookedCountRaw = booking.booked_count;
  //             coachesRaw = schedule.coaches.map(
  //               (scheduleCoach) =>
  //                 // if(scheduleCoach.coach_type == 'primary'){
  //                 // headCoachRaw = scheduleCoach; // Not used as of now since retrieving data about coach is costly
  //                 // }
  //                 scheduleCoach.display_name, // Get coach's display name
  //             );
  //           } else if (
  //             newBookDate < nextBookDateRaw &&
  //             booking.booked_count < schedule.max_athletes
  //           ) {
  //             nextBookDateRaw = DateTime.fromSQL(booking.booked_date);
  //             bookedCountRaw = booking.booked_count;
  //             coachesRaw = schedule.coaches.map(
  //               (scheduleCoach) => scheduleCoach.display_name,
  //             );
  //           }
  //         });
  //       }
  //       if (schedule.rates) {
  //         schedule.rates.forEach((rate) => {
  //           if (costRaw == null) {
  //             costRaw = rate.rate;
  //           } else if (
  //             isCostForAdultsRaw &&
  //             rate.golfer_type_str === 'Adult' &&
  //             rate.rate < costRaw
  //           ) {
  //             costRaw = rate.rate;
  //             isCostForAdultsRaw = true;
  //           } else if (
  //             !isCostForAdultsRaw &&
  //             rate.golfer_type_str !== 'Adult' &&
  //             rate.rate < costRaw
  //           ) {
  //             costRaw = rate.rate;
  //           }
  //         });
  //       }
  //     });
  //   }
  //   setNextBookDate(nextBookDateRaw);
  //   setBookedCount(bookedCountRaw);
  //   setCost(costRaw);
  //   setIsCostForAdults(isCostForAdultsRaw);
  //   setCoaches(coachesRaw);
  //   if (parseInt(`${eventSource.max_athletes}`, 10) - bookedCountRaw > 0) {
  //     setBookingAvail(true);
  //   }
  // }, [eventSource.max_athletes, eventSource.schedule]);

  return (
    <a
      href={new URL(eventSource.site, AppAttributes.datasiteDomain).toString()}
      className={`ts-card tssm-event-tab ${props.className || ''}`}
    >
      <div className="tssm-event-tab-cv-text">
        <div className="tssm-event-tab-cover-ctn">
          <img
            src={eventSource.artifacts[0].path || backgroundPlaceholder}
            className="tssm-event-tab-cover"
          />
        </div>
        <div className="tssm-event-tab-content">
          {/* <p
            className="tssm-event-tab-date"
          >
            {nextBookDate && (
              <>
                <span
                  className="tssm-event-tab-date-title"
                >
                  NEXT SESSION:{' '}
                </span>
                <span
                  className="tssm-event-tab-date-info"
                >
                  {nextBookDate.toFormat("cccc MMMM dd, yyyy - h':'mm a")}
                </span>
              </>
            )}
          </p> */}
          <p className="tssm-event-tab-name">{eventSource.title}</p>
          <div className="tssm-event-tab-loc">
            {eventSource.locations.name}, {eventSource.locations.city},{' '}
            {eventSource.locations.state_province},{' '}
            {eventSource.locations.country}
          </div>
        </div>
      </div>
      <div className="tssm-event-tab-price">
        <p className="tssm-event-tab-price-rate">
          ${getMinimumEventPrice(props.event)}
        </p>
      </div>
    </a>
  );
};
export default EventTab;

import { constructApiUrl, useSingletonAPIRequest } from '@thrivesports/shared';
import history from 'history/hash'; // eslint-disable-line node/file-extension-in-import
import { useContext, useEffect, useMemo, useState } from 'react';

import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { buildFetcher, SearchAPIParams } from '../utils/searchApi.js';
import {
  allSports,
  defaultResultSetSize,
  defaultSearchRadius,
} from './useDefaultSearchAPI.js';
import { useSearchParams } from './useSearchParams.js';

export type MapBounds = {
  northeastLatitude: number;
  northeastLongitude: number;
  southwestLatitude: number;
  southwestLongitude: number;
};

/**
 * This hook will perform a search from any given expected URL query parameters.
 *
 * It is safe to use this on multiple components as it uses a syncManager
 * to prevent simultaneous fetches.
 */
export const useSearchAPI = () => {
  const appAttributes = useContext(AppAttrsCtx);

  const [fetchParams, setFetchParams] = useState<SearchAPIParams>();

  const fetcher = useMemo(() => {
    if (fetchParams != null) {
      return buildFetcher(fetchParams);
    }
    return undefined;
  }, [fetchParams]);

  const {
    latitude: urlLatitude,
    longitude: urlLongitude,
    radius: urlRadius,
    sport: urlSport,
    zoom,
  } = useSearchParams();

  const { results, status } = useSingletonAPIRequest('search', fetcher);

  useEffect(() => {
    // ignore this search param value, it's for map navigation only
    if (urlRadius === 'point' || urlLatitude == null || urlLongitude == null) {
      return;
    }
    const apiSearchURL = constructApiUrl(appAttributes.targetApi, 'v1/search');

    apiSearchURL.searchParams.append('lat', urlLatitude);
    apiSearchURL.searchParams.append('lon', urlLongitude);

    const radius = urlRadius || `${defaultSearchRadius}`;
    if (radius === 'dynamic') {
      const {
        northeastLatitude,
        northeastLongitude,
        southwestLatitude,
        southwestLongitude,
      } = history.location.state as MapBounds;
      apiSearchURL.searchParams.append(
        'ne',
        `(${northeastLatitude}, ${northeastLongitude})`,
      );
      apiSearchURL.searchParams.append(
        'sw',
        `(${southwestLatitude}, ${southwestLongitude})`,
      );
      apiSearchURL.searchParams.append('radius', `${defaultSearchRadius}`);
    } else {
      apiSearchURL.searchParams.append('radius', radius);
    }

    if (urlSport != null && urlSport?.toLocaleUpperCase() !== allSports) {
      apiSearchURL.searchParams.append('sport', urlSport);
    }

    apiSearchURL.searchParams.append('size', `${defaultResultSetSize}`);

    setFetchParams({
      url: apiSearchURL.toString(),
      partner: appAttributes.partner,
    });
  }, [
    appAttributes.partner,
    appAttributes.targetApi,
    urlLatitude,
    urlLongitude,
    urlRadius,
    urlSport,
    zoom,
  ]);

  return { results, status };
};

import 'normalize.css';
// This is manually updated as of now since there is no thriv Figma -> see TSP-2640
import '@thrivesports/design-system/src/scss/web/index.scss';
import './style/tsi/tsi-style.css';
import './style/index.scss';

import {
  Attriset,
  constructApiUrl,
  hideLoadingDiv,
} from '@thrivesports/shared';
import React, { ReactNode } from 'react';
// eslint-disable-next-line node/file-extension-in-import
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';

import { defaultScriptAttrRequirements } from './attributes/defaultScriptAttrRequirements.js';
import scriptAttrRequirements, {
  ScriptAttrRequirementsInterface,
} from './attributes/scriptAttrRequirements.js';
import { AppAttrsContextProvider } from './context/AppAttrsCtx.js';
import type { UserLocationInterface } from './context/UserLocationCtx.js';
import { UserLocationContextProvider } from './context/UserLocationCtx.js';
import setStyleVars from './utils/setStyleVars.js';
import type { MapPoint } from './utils/types.js';
import CoachesView from './views/CoachesView.js';
import ContentView from './views/ContentView.js';
import EventsView from './views/EventsView.js';
import MapPreviewView from './views/MapPreviewView.js';
import SearchBarRouterView from './views/SearchBarRouterView.js';
import SuggestedLocationsView from './views/SuggestedLocationsView.js';

(() => {
  const injectReactView = (
    className: string,
    viewComponent: ReactNode,
    appAttributes: ScriptAttrRequirementsInterface,
    userLocation: UserLocationInterface,
  ): void => {
    const elementMatches = Array.from(
      document.getElementsByClassName(className),
    );
    elementMatches.forEach((ele) => {
      ele.classList.add('ts');
      ele.classList.add('tssm');
      const root = createRoot(ele);
      root.render(
        <AppAttrsContextProvider {...{ appAttributes }}>
          <UserLocationContextProvider {...userLocation}>
            {viewComponent}
          </UserLocationContextProvider>
        </AppAttrsContextProvider>,
      );
    });
  };

  window.addEventListener('DOMContentLoaded', async () => {
    // Get appAttrs and user location b4 rendering
    Promise.all([
      Attriset(scriptAttrRequirements, defaultScriptAttrRequirements),
    ]).then(async (arr) => {
      const appAttributes: ScriptAttrRequirementsInterface = arr[0];
      const userLocation = await setUserLocation(appAttributes);

      /* else if (
      geoLocation // If there is no location provided with currentLat/Lng or currentCityState, use location services
    ) {
      const point = {
        lat: geoLocation.coords.latitude,
        lng: geoLocation.coords.longitude,
      };
      try {
        return await getLocationFromLatLng(point);
      } catch (err) {
        return {
          locationInfo: null,
          ...point,
        };
      }
      // If all else fails, use the first location in suggested locations
    } */

      injectReactView(
        appAttributes.contentInjectionSiteClassName,
        <ContentView />,
        appAttributes,
        userLocation,
      );

      injectReactView(
        appAttributes.navbarInjectionSiteClassName,
        <SearchBarRouterView />,
        appAttributes,
        userLocation,
      );

      injectReactView(
        appAttributes.mapTileSectionInjectionSiteClassName,
        <Router>
          <MapPreviewView />
        </Router>,
        appAttributes,
        userLocation,
      );

      injectReactView(
        appAttributes.coachesSectionInjectionSiteClassName,
        <Router>
          <CoachesView />
        </Router>,
        appAttributes,
        userLocation,
      );

      injectReactView(
        appAttributes.eventsSectionInjectionSiteClassName,
        <Router>
          <EventsView />
        </Router>,
        appAttributes,
        userLocation,
      );

      injectReactView(
        appAttributes.suggestedLocationsSectionInjectionSiteClassName,
        <Router>
          <SuggestedLocationsView />
        </Router>,
        appAttributes,
        userLocation,
      );

      setStyleVars(appAttributes);

      hideLoadingDiv();
    });
  });

  const setUserLocation = async (
    appAttributes: ScriptAttrRequirementsInterface,
  ): Promise<UserLocationInterface> => {
    const url = constructApiUrl(
      appAttributes.targetApi,
      'v2/proxy-google-places/findplacefromtext/json',
    );

    // Complicated mess of determining where the user is
    if (
      appAttributes.currentLat != null &&
      appAttributes.currentLng != null &&
      !appAttributes.currentCityStateOverride // If we are overriding with current city state, dont use currentLat and currentLng
    ) {
      const point = {
        lat: appAttributes.currentLat,
        lng: appAttributes.currentLng,
      };
      url.searchParams.append(
        'location',
        `${appAttributes.currentLat},${appAttributes.currentLng}`,
      );
      url.searchParams.append('fields', ['place_id'].toString());
      try {
        return await getLocationFromLatLng(point);
      } catch (err) {
        return {
          locationInfo: null,
          latLng: point,
        };
      }
    } else if (
      appAttributes.currentCityState // If provided, use currentCityState
    ) {
      url.searchParams.append('input', appAttributes.currentCityState);
      url.searchParams.append('inputtype', 'textquery');
      url.searchParams.append(
        'fields',
        ['name', 'geometry', 'formatted_address', 'place_id'].toString(),
      );
      try {
        if (window.google?.maps != null && window.google.maps.places == null) {
          await google.maps.importLibrary('places');
        }
        return await lookUpLocation(appAttributes.currentCityState);
      } catch (err) {
        if (appAttributes.suggestedLocations.length > 0) {
          return {
            locationInfo: null,
            latLng: {
              lat: appAttributes.suggestedLocations[0].latitude,
              lng: appAttributes.suggestedLocations[0].longitude,
            },
          };
        }
        return {
          locationInfo: null,
          latLng: {
            lat: defaultScriptAttrRequirements.suggestedLocations[0].latitude,
            lng: defaultScriptAttrRequirements.suggestedLocations[0].longitude,
          },
        };
      }
    } else if (appAttributes.suggestedLocations.length > 0) {
      url.searchParams.append(
        'location',
        `${appAttributes.suggestedLocations[0].latitude},${appAttributes.suggestedLocations[0].longitude}`,
      );
      url.searchParams.append('fields', ['place_id'].toString());
      const point = {
        lat: appAttributes.suggestedLocations[0].latitude,
        lng: appAttributes.suggestedLocations[0].longitude,
      };

      try {
        return await getLocationFromLatLng(point);
      } catch (err) {
        return {
          locationInfo: null,
          latLng: point,
        };
      }
    } else {
      const point = {
        lat: defaultScriptAttrRequirements.suggestedLocations[0].latitude,
        lng: defaultScriptAttrRequirements.suggestedLocations[0].longitude,
      };
      url.searchParams.append('location', `${point.lat},${point.lng}`);
      url.searchParams.append('fields', ['place_id'].toString());
      try {
        return await getLocationFromLatLng(point);
      } catch (err) {
        return {
          locationInfo: null,
          latLng: point,
        };
      }
    }
  };

  async function getLocationFromLatLng({ lat, lng }: MapPoint) {
    const geocoder = new google.maps.Geocoder();
    const results = await geocoder.geocode({
      location: {
        lat,
        lng,
      },
    });
    const userLocation: UserLocationInterface = {
      locationInfo: {
        address_components: results.results[0].address_components,
        formatted_address: results.results[0].formatted_address,
        place_id: results.results[0].place_id,
      },
      latLng: {
        lat: lat || results.results[0].geometry.location.lat(),
        lng: lng || results.results[0].geometry.location.lng(),
      },
    };
    return userLocation;
  }

  function lookUpLocation(
    currentCityState: string,
  ): Promise<UserLocationInterface> {
    return new Promise((resolve) => {
      const service = new google.maps.places.PlacesService(
        document.createElement('div'),
      );
      service.findPlaceFromQuery(
        {
          query: currentCityState,
          fields: ['name', 'geometry', 'formatted_address', 'place_id'],
          // locationBias:{
          //   lat: appAttributes.currentLat,
          //   lng: appAttributes.currentLng
          // }
        },
        (results, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            results != null &&
            results.length > 0 &&
            results[0].geometry &&
            results[0].geometry.location
          ) {
            const userLocation: UserLocationInterface = {
              locationInfo: null,
              latLng: {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              },
            };
            if (
              results[0].address_components ||
              results[0].formatted_address ||
              results[0].place_id
            ) {
              userLocation.locationInfo = {
                address_components: results[0].address_components,
                formatted_address: results[0].formatted_address,
                place_id: results[0].place_id,
              };
            }
            resolve(userLocation);
          }
        },
      );
    });
  }
})();

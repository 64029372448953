/* eslint-disable no-underscore-dangle */
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import type { ClusterIconStyle } from '@react-google-maps/marker-clusterer';
import { Loading } from '@thrivesports/design-system';
import {
  DbElasticSearchCoach,
  fromDbElasticSearchCoach,
  PublicCoach,
} from '@thrivesports/shared';
import history from 'history/hash'; // eslint-disable-line node/file-extension-in-import
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import { CoachCard } from '../components/CoachCard.js';
import CoachTab from '../components/CoachTab.js';
import EventRow from '../components/EventRow.js';
import EventTab from '../components/EventTab.js';
import { RadiusSelector } from '../components/RadiusSelector.js';
import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { UserLocationCtx } from '../context/UserLocationCtx.js';
import {
  allSports,
  defaultResultSetSize,
  useDefaultSearchAPI,
} from '../hooks/useDefaultSearchAPI.js';
import {
  defaultZoom,
  useDefaultSearchRequest,
} from '../hooks/useDefaultSearchRequest.js';
import { useGeocodedLocation } from '../hooks/useGeocodedLocation.js';
import { useIsAtUserLocation } from '../hooks/useIsAtUserLocation.js';
import { MapBounds, useSearchAPI } from '../hooks/useSearchAPI.js';
import { useSearchParams } from '../hooks/useSearchParams.js';
import getLocationsFromResults, {
  LocationsInterface,
} from '../utils/getLocationsFromResults.js';
import { updateHashSearchParam } from '../utils/navigation.js';
import type {
  MapPoint,
  SearchAPIRawResponse,
  SearchResultsCoachHitInterface,
  SearchResultsEventHitInterface,
} from '../utils/types.js';

type SelectedLocation = {
  point: MapPoint;
  hits: SearchResultsCoachHitInterface[] | SearchResultsEventHitInterface[];
};

const MapResultsView = () => {
  const appAttributes = useContext(AppAttrsCtx);
  const userLocation = useContext(UserLocationCtx);

  useDefaultSearchRequest();

  const { results: defaultData, status: defaultSearchStatus } =
    useDefaultSearchAPI();
  const { results: searchData, status: searchStatus } = useSearchAPI();

  const data = searchData || defaultData;

  const geocodedLocation = useGeocodedLocation();

  const {
    latitude,
    longitude,
    zoom,
    resultType: selected,
    sport: sportCode,
  } = useSearchParams();

  const [mapLoadCenter, setMapLoadCenter] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: Number(latitude) || userLocation.latLng.lat,
    lng: Number(longitude) || userLocation.latLng.lng,
  });
  const [mapLoadZoom, setMapLoadZoom] = useState<number>(
    Number(zoom) || defaultZoom,
  );
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const [showSearchHere, setShowSearchHere] = useState<boolean>(false); // Show the Search Here button

  // todo use URL state instead. we should set radius=point and then use the history library
  // to set the point + results in URL state
  const [selectedLocation, setSelectedLocation] =
    useState<SelectedLocation | null>(null);

  const [isMobile, setIsMobile] = useState<false | 'list' | 'map'>(false);

  useEffect(() => {
    const resizeListener = () => {
      if (window.innerWidth <= 3000 && isMobile === false) {
        // This is based off the $tssm-md-rule: 720px;
        setIsMobile('map');
      } else if (window.innerWidth > 3000 && isMobile !== false) {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', resizeListener);
    resizeListener(); // Run once to set isMobile default value
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [isMobile]);

  useEffect(() => {
    if (map && latitude && longitude) {
      setMapLoadCenter({
        lat: Number(latitude),
        lng: Number(longitude),
      });
      if (zoom != null) {
        setMapLoadZoom(Number(zoom));
      }
    }
  }, [latitude, longitude, map, zoom]);

  // scroll to top on first render
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const locations = useMemo(() => {
    if (data) {
      return getLocationsFromResults(data);
    }
    return null;
  }, [data]);

  const coaches = useMemo(
    () =>
      data?.results.coaches.hits.map((hit) =>
        // eslint-disable-next-line no-underscore-dangle
        fromDbElasticSearchCoach(hit._source as DbElasticSearchCoach),
      ),
    [data?.results.coaches.hits],
  );

  let resultsListClass = '';
  if (isMobile === 'list') {
    resultsListClass = 'active';
  } else if (isMobile !== false) {
    resultsListClass = 'disabled';
  }

  let resultsMapClass = '';
  if (isMobile === 'map') {
    resultsMapClass = 'active';
  } else if (isMobile !== false) {
    resultsMapClass = 'disabled';
  }

  let sportLabel = null;
  if (sportCode != null) {
    sportLabel =
      appAttributes.sportDropdownWhitelist.find((s) => s.code === sportCode)
        ?.name || 'All Sports';
  }

  return (
    <div className={`tssm-map-results ${isMobile !== false ? 'mobile' : ''}`}>
      {searchStatus === 'loading' || defaultSearchStatus === 'loading' ? (
        <Loading text="Searching..." />
      ) : (
        <div className={`tssm-map-results-list ${resultsListClass}`}>
          <div className="tssm-map-results-list-heading">
            <div className="tssm-map-results-list-heading-left">
              <div className="tssm-map-results-result-title">
                <div className="tssm-map-results-result-title-sport">
                  <span>{sportLabel}</span>
                </div>
                <div className="tssm-map-results-result-title-location">
                  <span>
                    {geocodedLocation != null
                      ? `${geocodedLocation.city}, ${geocodedLocation.state}, ${geocodedLocation.country}`
                      : null}
                  </span>
                </div>
              </div>
            </div>
            <div className="tssm-map-results-list-heading-right">
              <ResultSummary
                selectedPoint={selectedLocation?.point}
                {...{ resultType: selected, data }}
              />
              <div
                className={`tssm-map-results-mobile-mode ${
                  isMobile !== false ? 'active' : ''
                }`}
              ></div>

              <div className="tssm-map-results-type">
                <button
                  onClick={() => {
                    updateHashSearchParam('results', 'coaches');
                  }}
                  className={`ts-btn tssm-map-results-type-btn tssm-map-results-type-btn-coaches ${
                    selected === 'coaches' ? 'active' : ''
                  }`}
                >
                  Coaches
                </button>
                <button
                  onClick={() => {
                    updateHashSearchParam('results', 'events');
                  }}
                  className={`ts-btn tssm-map-results-type-btn tssm-map-results-type-btn-events ${
                    selected === 'events' ? 'active' : ''
                  }`}
                >
                  Events
                </button>
              </div>

              <div className="map-control-btn">
                <button
                  onClick={() => {
                    if (isMobile === 'list') {
                      setIsMobile('map');
                    } else {
                      setIsMobile('list');
                    }
                  }}
                  className="tssm-map-results-mobile-mode-btn ts-btn"
                >
                  {isMobile === 'list' ? 'List' : 'Map'}
                </button>
              </div>
            </div>
          </div>

          <div className={`tssm-map-results-map ${resultsMapClass}`}>
            <div className="tssm-map-results-map-inner">
              {Boolean(data) && showSearchHere && (
                <div className="tssm-map-results-search-here">
                  <div
                    onClick={() => {
                      if (map == null) {
                        return;
                      }
                      const queryParams = new URLSearchParams(
                        history.location.search,
                      );
                      const mapCenterLat = map.getCenter()?.lat();
                      const mapCenterLng = map.getCenter()?.lng();
                      if (mapCenterLat != null && mapCenterLng != null) {
                        queryParams.set('latitude', `${mapCenterLat}`);
                        queryParams.set('longitude', `${mapCenterLng}`);
                      }
                      const mapZoom = map.getZoom();
                      if (mapZoom != null) {
                        queryParams.set('zoom', `${map.getZoom()}`);
                      }
                      queryParams.delete('term');
                      queryParams.set('radius', 'dynamic');

                      const ne = map.getBounds()?.getNorthEast();
                      const sw = map.getBounds()?.getSouthWest();

                      if (ne && sw) {
                        setShowSearchHere(false);
                        history.push(
                          {
                            search: `?${queryParams.toString()}`,
                          },
                          {
                            northeastLatitude: ne.lat(),
                            northeastLongitude: ne.lng(),
                            southwestLatitude: sw.lat(),
                            southwestLongitude: sw.lng(),
                          } as MapBounds,
                        );
                      }
                    }}
                    className="ts-btn tssm-map-results-search-here-btn"
                  >
                    <i className="tsi-location"></i>
                    Search Here
                  </div>
                </div>
              )}
              {data && selectedLocation && (
                <div className="tssm-map-results-sel-loc">
                  <ScrollContainer
                    horizontal
                    hideScrollbars
                    className="tssm-map-results-sel-loc-scroll"
                  >
                    {selectedLocation.hits.map((thing, thingIndex) => {
                      if (selected === 'coaches') {
                        const coach: SearchResultsCoachHitInterface =
                          thing as SearchResultsCoachHitInterface;
                        return <CoachTab key={thingIndex} coach={coach} />;
                      }
                      if (selected === 'events') {
                        const event: SearchResultsEventHitInterface =
                          thing as SearchResultsEventHitInterface;
                        return <EventTab key={thingIndex} event={event} />;
                      }
                      return null;
                    })}
                  </ScrollContainer>
                </div>
              )}
              <GoogleMap
                mapContainerStyle={{
                  height: '300px',
                  width: '100%',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSelectedLocation(null); // Reset selected location to no coaches or events
                }}
                onDrag={() => {
                  setShowSearchHere(true);
                }}
                onZoomChanged={() => {
                  // this gets called before onLoad
                  if (map) {
                    setShowSearchHere(true);
                    const newZoom = map.getZoom();
                    if (newZoom != null) {
                      setMapLoadZoom(newZoom);
                    }
                  }
                }}
                center={mapLoadCenter}
                zoom={mapLoadZoom}
                onLoad={(loadedMap) => {
                  setMap(loadedMap);
                }}
                onUnmount={() => {
                  setMap(null);
                }}
                options={{
                  maxZoom: 20,
                  minZoom: 8,
                  gestureHandling: 'greedy',
                  // disableDefaultUI: true,
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_CENTER,
                  },
                }}
              >
                <Marker
                  position={{
                    lat: Number(latitude),
                    lng: Number(longitude),
                  }}
                  icon={appAttributes.mapCenterIcon}
                  onClick={() => {}}
                />
                {selected === 'coaches' && (
                  <CoachCluster {...{ data, locations, setSelectedLocation }} />
                )}

                {selected === 'events' && (
                  <EventCluster {...{ data, locations, setSelectedLocation }} />
                )}
              </GoogleMap>
            </div>
          </div>

          <div className="tssm-map-results-list-items">
            <div className="tssm-coaches-ctn tssm-events-ctn tssm-map-results-grid">
              {selected === 'coaches' ? (
                <CoachResults coaches={coaches} />
              ) : (
                <EventResults {...{ data }} />
              )}
            </div>
          </div>

          <div className="tssm-map-results-bottom">
            <button
              type="button"
              className="btn tssm-map-results-scroll-to-top-btn"
              onClick={() => {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
              }}
            >
              Scroll to Top
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

type MarkerClusterProps = {
  data?: SearchAPIRawResponse;
  locations: LocationsInterface | null;
};

const CoachCluster: React.FC<MarkerClusterProps> = ({ data, locations }) => {
  const appAttributes = useContext(AppAttrsCtx);

  let styles: ClusterIconStyle[] = Array(6);

  styles = styles.fill({
    url: appAttributes.mapMultiIcon,
    height: 50,
    width: 50,
    textColor: 'white',
  });

  const getCoachesAtLoc = (point: MapPoint) => {
    const coachesAtLoc: SearchResultsCoachHitInterface[] = [];
    if (data) {
      data.results.coaches.hits.forEach((coach) => {
        coach._source.locations.forEach((loc) => {
          if (loc.point.lat === point.lat && loc.point.lon === point.lng) {
            coachesAtLoc.push(coach);
          }
        });
      });
    }
    return coachesAtLoc;
  };

  return (
    <MarkerClusterer
      styles={styles}
      onClick={(e) => {
        // If only one cluster, that means its a location and not location cluster
        const pos = e.getMarkers()[0].getPosition();
        if (e.getClusterer().getClusters().length === 1 && data && pos) {
          const latLng = {
            lat: pos.lat(),
            lng: pos.lng(),
          };

          navigateToMapPoint({
            point: latLng,
            hits: getCoachesAtLoc(latLng),
          });
        }
      }}
    >
      {(clusterer) => (
        <>
          {locations?.coachLocations.map((location, lIndex) => (
            <Marker
              key={lIndex}
              position={location}
              clusterer={clusterer}
              icon={appAttributes.mapSingleIcon}
              onClick={() => {
                if (data) {
                  navigateToMapPoint({
                    point: location,
                    hits: getCoachesAtLoc(location),
                  });
                }
              }}
            />
          ))}
        </>
      )}
    </MarkerClusterer>
  );
};

type EventClusterProps = {
  data?: SearchAPIRawResponse;
  locations: LocationsInterface | null;
};

const EventCluster: React.FC<EventClusterProps> = ({ data, locations }) => {
  const appAttributes = useContext(AppAttrsCtx);

  let styles: ClusterIconStyle[] = Array(6);
  styles = styles.fill(
    {
      url: appAttributes.mapMultiIcon,
      height: 50,
      width: 50,
      textColor: 'white',
    },
    0,
  );

  return (
    <MarkerClusterer
      styles={styles}
      onClick={(e) => {
        // If only one cluster, that means its a location and not location cluster
        const pos = e.getMarkers()[0].getPosition();
        if (e.getClusterer().getClusters().length === 1 && data && pos) {
          const latLng = {
            lat: pos.lat(),
            lng: pos.lng(),
          };
          const eventsAtLoc: SearchResultsEventHitInterface[] = [];
          data.results.events.hits.forEach((event) => {
            const loc = event._source.locations;
            if (loc.point.lat === latLng.lat && loc.point.lon === latLng.lng) {
              eventsAtLoc.push(event);
            }
          });
          navigateToMapPoint({
            point: latLng,
            hits: eventsAtLoc,
          });
        }
      }}
    >
      {(clusterer) => (
        <>
          {locations?.eventLocations.map((location, lIndex) => (
            <Marker
              key={lIndex}
              position={location}
              clusterer={clusterer}
              icon={appAttributes.mapSingleIcon}
              onClick={() => {
                if (data) {
                  const eventsAtLoc: SearchResultsEventHitInterface[] = [];
                  data.results.events.hits.forEach((event) => {
                    const loc = event._source.locations;
                    if (
                      loc.point.lat === location.lat &&
                      loc.point.lon === location.lng
                    ) {
                      eventsAtLoc.push(event);
                    }
                  });
                  navigateToMapPoint({
                    point: location,
                    hits: eventsAtLoc,
                  });
                }
              }}
            />
          ))}
        </>
      )}
    </MarkerClusterer>
  );
};

type ResultSummaryProps = {
  resultType: string;
  data?: SearchAPIRawResponse;
  selectedPoint?: MapPoint;
};

const ResultSummary: React.FC<ResultSummaryProps> = ({ resultType, data }) => {
  const { radius } = useSearchParams();

  const selectedLocation = useMemo(() => {
    if (data && radius === 'point') {
      const { point } = (history.location.state as SelectedLocation) || {};
      if (point) {
        const locations = getLocationsFromResults(data);
        const loc = locations.coachLocations.find(
          (l) => l.lat === point.lat && l.lng === point.lng,
        );
        if (loc) {
          return loc.location;
        }
      }
    }
    return null;
  }, [data, radius]);

  if (data == null) {
    return null;
  }

  let resultsCount;
  if (resultType === 'events') {
    resultsCount = data.results.events.hits.length;
  } else if (resultType === 'coaches') {
    resultsCount = data.results.coaches.hits.length;
  }

  let resultsLabel =
    resultsCount === defaultResultSetSize
      ? `${defaultResultSetSize}`
      : `${resultsCount}`;

  if (selectedLocation) {
    const { hits } = (history.location.state as SelectedLocation) || {};
    if (hits) {
      resultsLabel = `${hits.length}`;
    }
  }
  let resultTypeLabel = resultType === 'events' ? 'events' : 'coaches';
  if (resultsCount === 1) {
    resultTypeLabel = resultType === 'events' ? 'event' : 'coach';
  }

  return (
    <div className="tssm-map-results-result-num">
      <span className="tssm-map-results-result-num-text-ctn">
        <span className="tssm-map-results-result-num-highlight">
          {' '}
          {resultsLabel}{' '}
        </span>
        <span className="tssm-map-results-result-num-text">
          {resultTypeLabel}
        </span>
      </span>

      {selectedLocation ? (
        <>
          <span className="tssm-map-results-result-num-text">at </span>
          <span className="tssm-map-results-sel-loc-title">
            {selectedLocation.name}, {selectedLocation.address_1}{' '}
            {selectedLocation.city}, {selectedLocation.state_province},{' '}
            {selectedLocation.country}
          </span>
        </>
      ) : (
        <>
          <RadiusSelector
            className="ts-btn tssm-map-results-radius-sel "
            itemOptionClassName="tssm-map-results-radius-sel-items"
          />
        </>
      )}
    </div>
  );
};

const EventResults: React.FC<{
  data?: SearchAPIRawResponse;
}> = ({ data }) => {
  const results = data?.results.events.hits;

  return (
    <>
      {data && results?.length === 0 ? <NoResults searchFor="events" /> : null}
      {results?.slice(0, 100).map((event, eventIndex) => (
        <EventRow key={eventIndex} event={event} className="tssm-event-row" />
      ))}
      {results && results.length > 100 ? (
        <div className="tssm-map-results-see-more">
          Only showing the first 100 events of {results.length}. Refine your
          search to see more results.
        </div>
      ) : null}
    </>
  );
};

const CoachResults: React.FC<{ coaches?: PublicCoach[] }> = ({ coaches }) => {
  const { radius } = useSearchParams();

  if (radius === 'point') {
    const { hits } = (history.location.state as SelectedLocation) || {};
    if (hits) {
      // results = hits as SearchResultsCoachHitInterface[];
    }
  }

  return (
    <>
      {coaches?.length === 0 ? <NoResults searchFor="coaches" /> : null}
      {coaches?.slice(0, 100).map((coach, coachIndex) => (
        <CoachCard key={coachIndex} coach={coach} className="tssm-coach-row" />
      ))}
      {coaches && coaches.length > 100 ? (
        <div className="tssm-map-results-see-more">
          Only showing the first 100 coaches of {coaches.length}. Refine your
          search to see more results.
        </div>
      ) : null}
    </>
  );
};

const NoResults: React.FC<{ searchFor: string }> = ({ searchFor }) => {
  const appAttributes = useContext(AppAttrsCtx);
  const isAtUserLocation = useIsAtUserLocation();
  const { sport: urlSport } = useSearchParams();

  const sportLabel = useMemo(() => {
    if (urlSport && urlSport !== allSports) {
      const sport = appAttributes.sportDropdownWhitelist.find(
        (s) => s.code === urlSport,
      );
      if (sport) {
        return sport.lowercased();
      }
    }
    return '';
  }, [appAttributes.sportDropdownWhitelist, urlSport]);

  return (
    <div className="tssm-map-results-list-no-res">
      <h4 className="tssm-map-results-list-no-res-title">No exact matches</h4>
      <p className="tssm-map-results-list-no-res-txt">
        Uh-oh! We couldn't find any {sportLabel} {searchFor}{' '}
        {isAtUserLocation ? 'near you' : 'here'}.
      </p>
      <p className="tssm-map-results-list-no-res-sub">
        Try adjusting your map to explore more coaches and events.
      </p>
    </div>
  );
};

const navigateToMapPoint = (location: SelectedLocation) => {
  const queryParams = new URLSearchParams(history.location.search);
  queryParams.set('radius', 'point');
  history.push(
    {
      search: `?${queryParams.toString()}`,
    },
    location,
  );
};

export default MapResultsView;

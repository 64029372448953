import React, { PropsWithChildren, useState } from 'react';

import { defaultScriptAttrRequirements } from '../attributes/defaultScriptAttrRequirements.js';

export interface UserLocationInfoInterface {
  address_components?: google.maps.GeocoderAddressComponent[];
  place_id?: string;
  formatted_address?: string;
  name?: string;
}

export type UserLocationLatLng = {
  lat: number;
  lng: number;
};

export type UserLocationInterface = {
  locationInfo: UserLocationInfoInterface | null;
  latLng: UserLocationLatLng;
};

export const UserLocationCtx = React.createContext<
  UserLocationInterface & {
    isDefaultLocation: boolean;
    setLatLng: (latLng: UserLocationLatLng) => void;
  }
>({
  locationInfo: null,
  latLng: {
    lat: defaultScriptAttrRequirements.suggestedLocations[0].latitude,
    lng: defaultScriptAttrRequirements.suggestedLocations[0].longitude,
  },
  isDefaultLocation: true,
  setLatLng: () => {},
}); // Def to default first suggested location

export type UserLocationContextProviderProps =
  PropsWithChildren<UserLocationInterface>;

export const UserLocationContextProvider: React.FC<
  UserLocationContextProviderProps
> = ({ children, latLng: latLngProps }) => {
  const [latLng, setLatLng] = useState<UserLocationLatLng>({
    lat: latLngProps.lat,
    lng: latLngProps.lng,
  });

  return (
    <UserLocationCtx.Provider
      value={{
        latLng,
        setLatLng,
        isDefaultLocation:
          latLng.lat === latLngProps.lat && latLng.lng === latLngProps.lng,
        locationInfo: null,
      }}
    >
      {children}
    </UserLocationCtx.Provider>
  );
};

import { StarRating } from '@thrivesports/design-system';
import { avatarPlaceholder } from '@thrivesports/shared';
import React, { useContext } from 'react';

import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { UserLocationCtx } from '../context/UserLocationCtx.js';
import lineDistance from '../utils/geometry/lineDistance.js';
import type { SearchResultsCoachHitInterface } from '../utils/types.js';

export interface CoachTabPropsInterface {
  coach: SearchResultsCoachHitInterface;
  className?: string;
}

const CoachTab = (props: CoachTabPropsInterface) => {
  const AppAttributes = useContext(AppAttrsCtx);
  const { latLng } = useContext(UserLocationCtx);

  // eslint-disable-next-line no-underscore-dangle
  const coachSource = props.coach._source;

  return (
    <a
      href={new URL(coachSource.site, AppAttributes.datasiteDomain).toString()}
      className={`tssm-coach-tab ts-card ${props.className || ''}`}
    >
      <div className="tssm-coach-tab-av-text">
        <div className="tssm-coach-tab-avatar-ctn">
          <img
            src={coachSource?.avatar?.m || avatarPlaceholder}
            className="tssm-coach-tab-avatar"
          />
        </div>
        <div className="tssm-coach-tab-content">
          <p className="tssm-coach-tab-name">{coachSource.name}</p>
          <StarRating
            stars={coachSource.review_totals?.average_stars || 0}
            className="tssm-coach-tab-rating"
          />
          <div className="tssm-coach-tab-loc">
            {(() => {
              const locationsByDistance = coachSource.locations.sort((a, b) => {
                // Locations sorted by distance in miles
                const aD = lineDistance(
                  a.point.lat,
                  a.point.lon,
                  latLng.lat,
                  latLng.lng,
                );
                const bD = lineDistance(
                  b.point.lat,
                  b.point.lon,
                  latLng.lat,
                  latLng.lng,
                );
                return bD - aD;
              });
              const closestLoc = locationsByDistance[0];
              const delta = lineDistance(
                closestLoc.point.lat,
                closestLoc.point.lon,
                latLng.lat,
                latLng.lng,
              );
              return (
                <>
                  <p className="tssm-coach-tab-loc-name">{closestLoc.name}</p>
                  <p className="tssm-coach-tab-loc-city-st">
                    {closestLoc.address_1}, {closestLoc.city},{' '}
                    {closestLoc.state_province}
                  </p>
                  <p className="tssm-coach-tab-loc-dist">
                    <i className="tsi-location"></i>
                    {delta > 1
                      ? `${delta.toFixed(0)} miles`
                      : 'less than a mile'}
                  </p>
                </>
              );
            })()}
          </div>
        </div>
        {Number(coachSource.rates.min) > 0 && (
          <div className="tssm-coach-tab-price">
            {/* <p
              className="tssm-coach-tab-price-start"
            >
              Starting at
            </p> */}
            <p className="tssm-coach-tab-price-rate">
              ${coachSource.rates && Number(coachSource.rates.min)}
            </p>
          </div>
        )}
      </div>
    </a>
  );
};
export default CoachTab;

import { Loading } from '@thrivesports/design-system';
import {
  DbElasticSearchCoach,
  fromDbElasticSearchCoach,
} from '@thrivesports/shared';
import history from 'history/hash'; // eslint-disable-line node/file-extension-in-import
import React, { useContext, useMemo } from 'react';

import { CoachCard } from '../components/CoachCard.js';
import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { useDefaultSearchAPI } from '../hooks/useDefaultSearchAPI.js';
import { useDefaultSearchRequest } from '../hooks/useDefaultSearchRequest.js';
import { useSearchAPI } from '../hooks/useSearchAPI.js';

const CoachesView = () => {
  const appAttributes = useContext(AppAttrsCtx);

  useDefaultSearchRequest();

  const { results: defaultData, status: defaultSearchStatus } =
    useDefaultSearchAPI();
  const { results: searchData, status: searchStatus } = useSearchAPI();

  const data = searchData || defaultData;

  const isEmpty = data?.results.coaches.hits?.length === 0;

  const coaches = useMemo(
    () =>
      data?.results.coaches.hits.map((hit) =>
        // eslint-disable-next-line no-underscore-dangle
        fromDbElasticSearchCoach(hit._source as DbElasticSearchCoach),
      ),
    [data?.results.coaches.hits],
  );

  return (
    <div className="tssm-coaches">
      {isEmpty && (
        <div className="tssm-coaches-not-found">
          <div>
            <h1>No Coaches Nearby</h1>
            <p className="tssm-coaches-not-found-txt">
              Try expanding your mile radius or select
            </p>
            <a
              href={(() => {
                const queryParams = new URLSearchParams(
                  history.location.search,
                );
                queryParams.set('results', 'coaches');
                const url = new URL(
                  `${appAttributes.navbarTargetPath}#/search/map?${queryParams}`,
                  window.location.origin,
                ).toString();
                return url;
              })()}
              className="ts-btn tssm-coaches-browse"
            >
              BROWSE MORE
            </a>
          </div>
        </div>
      )}
      {searchStatus === 'loading' || defaultSearchStatus === 'loading' ? (
        <Loading text="Searching..." />
      ) : (
        <>
          <p className="tssm-coaches-title">
            {coaches?.length ? (
              <>
                <span className="tssm-coaches-title-num">
                  {coaches.length < 100 ? `${coaches.length}` : `100+`}
                </span>
                <span className="tssm-coaches-title-txt">
                  {' '}
                  Featured Coaches
                </span>
              </>
            ) : null}
          </p>
          <div className="tssm-coaches-ctn">
            {coaches?.slice(0, 4).map((coach, coachIndex) => (
              <CoachCard
                key={coachIndex}
                coach={coach}
                className="tssm-coach-row"
              />
            ))}
          </div>
          {coaches?.length ? (
            <div className="tssm-coaches-browse-ctn ">
              <hr className="ts-divider tssm-coaches-browse-divider" />
              <a
                href={(() => {
                  const queryParams = new URLSearchParams(
                    history.location.search,
                  );
                  queryParams.set('results', 'coaches');
                  const url = new URL(
                    `${appAttributes.navbarTargetPath}#/search/map?${queryParams}`,
                    window.location.origin,
                  ).toString();
                  return url;
                })()}
                className=" tssm-coaches-browse "
              >
                BROWSE
              </a>
              <hr className="ts-divider tssm-coaches-browse-divider" />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
export default CoachesView;

import singleMapIcon from '../assets/cluster.svg';
import centerMapIcon from '../assets/location.svg';
import multiMapIcon from '../assets/mapicon.svg';
import type { ScriptAttrRequirementsInterface } from './scriptAttrRequirements.js';

const suggestedLocations = [
  {
    name: 'San Diego, CA',
    place: 'San Diego, CA',
    backgroundSrc:
      'https://s3.amazonaws.com/thriv/images/locations/locations_sandiego.jpg',
    latitude: 32.715736,
    longitude: -117.161087,
  },
  {
    name: 'Bay Area',
    place: 'San Francisco, CA',
    backgroundSrc:
      'https://thriv-marketplace.s3.us-east-1.amazonaws.com/locations/locations_bayarea.jpg',
    latitude: 37.774929,
    longitude: -122.419418,
  },
  {
    name: 'Scottsdale, AZ',
    place: 'Scottsdale, AZ',
    backgroundSrc:
      'https://thriv-marketplace.s3.us-east-1.amazonaws.com/locations/locations_scottsdale.jpg',
    latitude: 33.501324,
    longitude: -111.925278,
  },
  {
    name: 'New York City',
    place: 'New York, NY',
    backgroundSrc:
      'https://s3.amazonaws.com/thriv/images/locations/locations_newyork.jpg',
    latitude: 40.712776,
    longitude: -74.005974,
  },
  {
    name: 'Washington D.C',
    place: 'Washington, D.C',
    backgroundSrc:
      'https://thriv-marketplace.s3.us-east-1.amazonaws.com/locations/locations_washingtondc.jpg',
    latitude: 38.907192,
    longitude: -77.036873,
  },
  {
    name: 'Houston, TX',
    place: 'Houston, TX',
    backgroundSrc:
      'https://thriv-marketplace.s3.us-east-1.amazonaws.com/locations/locations_houston.png',
    latitude: 29.760427,
    longitude: -95.369804,
  },
];

export const defaultScriptAttrRequirements: ScriptAttrRequirementsInterface = {
  partner: 'thriv',
  partnerCode: '',
  targetApi: 'prod',
  contentInjectionSiteClassName:
    'tsp-search-marketplace-content-injection-site',
  navbarInjectionSiteClassName: 'tsp-search-marketplace-navbar-injection-site',
  navbarTargetPath: '/',
  navbarText: 'City, State, or zip',
  mapTileSectionInjectionSiteClassName:
    'tsp-search-marketplace-map-tile-section-injection-site',
  coachesSectionInjectionSiteClassName:
    'tsp-search-marketplace-coaches-section-injection-site',
  eventsSectionInjectionSiteClassName:
    'tsp-search-marketplace-events-section-injection-site',
  suggestedLocationsSectionInjectionSiteClassName:
    'tsp-search-marketplace-suggested-locations-section-injection-site',
  datasiteDomain:
    `${window.location.protocol}//${window.location.host}` ||
    'https://www.thrivsports.com/',
  suggestedLocations,
  sportDropdownWhitelist: [],
  mapSingleIcon: singleMapIcon,
  mapMultiIcon: multiMapIcon,
  mapCenterIcon: centerMapIcon,
  mapStyling: [],
};

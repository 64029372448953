import { Facility, GeoPoint, Location, PrivateLocation } from '../location.js';

/* AKA golfcourse */
export type DbLocation = {
  golfcourse_id: string;

  address_line_1?: string;
  city: string;
  state_province: string;
  zip_code: string;
  country: string;
  name: string;

  is_private?: string;
  encrypted_id?: string;
  group_facility?: DbGroupFacility;
};

export type DbLocationSearchResult = {
  id: string;
  label: string;
  value: string;

  address?: string;
  city: string;
  state_province: string;
  zip_code: string;
  country: string;
};

export type DbFacilitySearchResult = DbLocationSearchResult & {
  address: string;
  course_name: string;
};

export type DbGroupFacility = {
  group_facility_id: string;
};

export type DbMarketplaceLocation = {
  id: string;
  name: string;

  address_1?: string;
  city: string;
  state_province: string;
  zipcode: string;
  country: string;
  point: DbLocationPoint;
};

export type DbLocationPoint = {
  lat: string;
  lon: string;
};

export type DbElasticSearchLocation = {
  id: number;
  name: string;

  address_1?: string;
  city: string;
  state_province: string;
  zip: string;
  country: string;
  point: GeoPoint;
  distance: number;
};

export const fromDbLocationSearchResult = (
  dbLocationSearchResult: DbLocationSearchResult,
): PrivateLocation => {
  const privateLocation = new PrivateLocation();

  privateLocation.id = dbLocationSearchResult.id;
  privateLocation.address = dbLocationSearchResult.address;
  privateLocation.city = dbLocationSearchResult.city;
  privateLocation.stateProvince = dbLocationSearchResult.state_province;
  privateLocation.zipCode = dbLocationSearchResult.zip_code;
  privateLocation.country = dbLocationSearchResult.country;
  privateLocation.isPrivate = true;

  return privateLocation;
};

export const fromDbFacilitySearchResult = (
  dbFacilitySearchResult: DbFacilitySearchResult,
): Facility => {
  const facility = new Facility();
  facility.id = dbFacilitySearchResult.id;
  facility.name = dbFacilitySearchResult.course_name;
  facility.city = dbFacilitySearchResult.city;
  facility.stateProvince = dbFacilitySearchResult.state_province;
  facility.zipCode = dbFacilitySearchResult.zip_code;
  facility.country = dbFacilitySearchResult.country;
  facility.isPrivate = false;

  return facility;
};

export const fromDbLocation = (dbLocation: DbLocation): Location => {
  const location = new Location();
  location.id = dbLocation.golfcourse_id;

  location.address = dbLocation.address_line_1;
  location.city = dbLocation.city;
  location.stateProvince = dbLocation.state_province;
  location.zipCode = dbLocation.zip_code;
  location.country = dbLocation.country;

  location.isPrivate = false;

  location.encryptedId = dbLocation.encrypted_id;

  if (dbLocation.is_private === '1') {
    location.isPrivate = true;
    return location as PrivateLocation;
  }

  const facility = location as Facility;
  if (dbLocation.name) {
    facility.name = dbLocation.name;
  }
  return facility;
};

export const fromDbMarketplaceLocation = (
  dbAvailabilityLocation: DbMarketplaceLocation,
): Location => {
  const point: GeoPoint = {
    lat: parseFloat(dbAvailabilityLocation.point.lat),
    lon: parseFloat(dbAvailabilityLocation.point.lon),
  };

  const location = new Location();
  location.id = dbAvailabilityLocation.id;

  location.address = dbAvailabilityLocation.address_1;
  location.city = dbAvailabilityLocation.city;
  location.stateProvince = dbAvailabilityLocation.state_province;
  location.zipCode = dbAvailabilityLocation.zipcode;
  location.country = dbAvailabilityLocation.country;

  location.isPrivate = false;
  location.point = point;

  if (dbAvailabilityLocation.name) {
    location.isPrivate = true;

    const facility = location as Facility;
    facility.name = dbAvailabilityLocation.name;
    return facility;
  }
  return location as PrivateLocation;
};

export const fromDbElasticSearchLocation = (
  dbLocation: DbElasticSearchLocation,
): Location => {
  const location = new Location();
  location.id = `${dbLocation.id}`;

  location.address = dbLocation.address_1;
  location.city = dbLocation.city;
  location.stateProvince = dbLocation.state_province;
  location.zipCode = dbLocation.zip;
  location.country = dbLocation.country;

  location.isPrivate = false;
  location.point = dbLocation.point;

  if (dbLocation.name) {
    location.isPrivate = true;

    const facility = location as Facility;
    facility.name = dbLocation.name;
    return facility;
  }
  return location as PrivateLocation;
};
